<template>
  <div style="background-color: #f1f1f1" v-if="sponsorsList[0]">
    <v-container>
      <v-row>
      <v-col cols="9" sm="8" class="px-0">
      <v-col cols="12">
          <div
            class="headingParagraph"
            style="font-family: Roboto Slab; display: flex"
          >
            Our Sponsors
            <div class="titleLine"></div>
          </div>
        </v-col>
        <v-col cols="12" sm="12">
          <v-row>
            <v-col
              v-for="(item, i) in sponsorsList"
              :key="i"
              cols="4"
              sm="3"
              class="text-center d-flex justify-left mr-6"
            >
              <img
                class="sponsorImage"
                :src="item.sponsor_logo_url"
                alt=""
                @click="visitSponsorPage(item.website)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <v-col cols="3" sm="4">
      <img
            class="imageDiv"
            src="@/assets/publicDonationPage/sponsors.png"
            alt=""
          />
      </v-col>
      
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { API_PUBLIC_GET_SPONSOR_LIST } from "@/constants/APIUrls";
import { mapGetters } from "vuex";
export default {
  name: "SponsorsDetail",
  data() {
    return {
      sponsorsList: [],
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
  },
  methods: {
    visitSponsorPage(url) {
      url = url.match(/^https?:/) ? url : "//" + url;
      window.open(url, "_blank");
    },
    getSponsorsDetail() {
      const successHandler = (res) => {
        this.sponsorsList = res.data.sponsor_list;
        console.log(this.sponsorsList,"sponsors")
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      Axios.request_GET(
        API_PUBLIC_GET_SPONSOR_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    setTimeout(() => {
      this.getSponsorsDetail();
    }, 50);
  },
};
</script>
<style scoped>
.headingParagraph {
  font-weight: 600;
  font-style: normal;
  font-size: 25px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}
.imageDiv {
  float: right;
  width: 100%;
}
.imgLogo {
  position: absolute;
  top: 4.5%;
  right: 8%;
  height: 500px;
}
.titleLine {
  height: 4px;
  width: 50px;
  background-color: #2c1963;
  margin-left: 10px;
  margin-top: 18px;
  border-radius: 20px;
}
.sponsorImage {
 /* max-width: 450px;
  max-height: 250px;**/
  cursor: pointer;
  width:100%
}
@media only screen and (max-width: 600px) {
  .imageDiv {
    width: 100px;
  }
  .headingParagraph{
    font-size: 20px;
  }
  .titleLine{
    width: 25px;
    height: 3px;
    margin-top: 15px;
  }
  .sponsorImage{
    max-width: 75px;
    max-height: 37px;
  }
}
</style>
